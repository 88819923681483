import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='/images/buildings.jpg'
              text='Ver obras'
              label='Obras'
              path='/buildings/list'
            />
            <CardItem
              src='/images/clock_ins.jpg'
              text='Ver facturas'
              label='Facturas'
              path='/invoices/list'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='/images/products.jpg'
              text='Ver pedidos'
              label='Pedidos'
              path='/orders/list'
            />
            <CardItem
              src='/images/workers.png '
              text='Ver clientes'
              label='Clientes'
              path='/clients/list'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;