import React, { useState, useEffect } from "react";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useAuth } from "../pages/login/OAuth";
import { FaCoins, FaBuilding, FaUserTie, FaShoppingCart, FaClipboardList, FaTruck} from 'react-icons/fa';

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const menuItems = [
    {
        name: 'Clientes', icon: <FaUserTie />, path: '/clients', subMenu: [
            { name: 'Nuevo cliente', path: '/clients/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver clientes', path: '/clients/list', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver facturas', path: '/clients/invoices', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver retenciones', path: '/clients/retentions', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Proveedores', icon: <FaTruck />, path: '/providers', subMenu: [
            { name: 'Nuevo proveedor', path: '/providers/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver proveedores', path: '/providers/list', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver facturas', path: '/providers/invoices', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver retenciones', path: '/providers/retentions', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Obras', icon: <FaBuilding />, path: '/buildings', subMenu: [
            { name: 'Nueva obra', path: '/buildings/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver obras', path: '/buildings/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Ofertas', icon: <FaClipboardList />, path: '/offers', subMenu: [
            { name: 'Nueva oferta', path: '/offers/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver ofertas', path: '/offers/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Pedidos', icon: <FaShoppingCart />, path: '/orders', subMenu: [
            { name: 'Nuevo pedido', path: '/orders/new', allowedRol: ["admin", 'superadmin'] },
            { name: 'Ver pedidos', path: '/orders/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
    {
        name: 'Facturas', icon: <FaCoins />, path: '/invoices', subMenu: [
            { name: 'Ver facturas', path: '/invoices/list', allowedRol: ["admin", 'superadmin'] },
        ]
    },
];

export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const { authenticated, logout, userRole } = useAuth()

    const [openSubMenu, setOpenSubMenu] = useState(null);

    const handleSubMenuClick = (path) => {
        setOpenSubMenu(openSubMenu === path ? null : path);
        handleDrawerOpen();
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (!authenticated){
            handleDrawerClose();
        }
    }, [authenticated]);

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenSubMenu(false)
    };

    const handleClick = (path) => {
        setOpenSubMenu(false)
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ backgroundColor: '#E66136' }}>
                <Toolbar>
                    {authenticated && (

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" noWrap component={Link} to="/" color="inherit" sx={{ flexGrow: 1, textDecoration: 'none' }}>
                        LOGIMSA
                    </Typography>
                    
                    {authenticated ? (
                        <Typography variant="body1" noWrap color="inherit" component={Link} to="/" onClick={logout} sx={{ textDecoration: 'none' }}>
                            Cerrar sesión
                        </Typography>

                    ) : (
                        <></>
                    )}
                </Toolbar>
            </AppBar>
            {authenticated && (

                <Drawer variant="permanent" open={open}>
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />

                    <List>
                        {menuItems.map((item) => (
                            <div key={item.name}>
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    {item.subMenu ? (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 3.5,
                                            }}
                                            onClick={() => handleSubMenuClick(item.path)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                                            {open ? <ExpandMoreIcon /> : <></>}
                                        </ListItemButton>
                                    ) : (
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                            component={Link}
                                            to={item.path}
                                            onClick={() => handleClick(item.name)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 3 : 'auto',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                                        </ListItemButton>
                                    )}
                                </ListItem>
                                {item.subMenu && (
                                    <Collapse in={openSubMenu === item.path} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {item.subMenu.map((subItem) => {
                                                const showItem = subItem.allowedRol.includes(userRole);
                                                return showItem && (
                                                    <ListItem key={subItem.name} disablePadding>
                                                        <ListItemButton
                                                            sx={{
                                                                minHeight: 48,
                                                                justifyContent: 'center',
                                                                px: 4,
                                                            }}
                                                            component={Link}
                                                            to={subItem.path}
                                                            onClick={() => handleClick(item.name)}
                                                        >
                                                            <ListItemText primary={subItem.name} />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Collapse>
                                )}
                            </div>
                        ))}
                    </List>


                </Drawer>
            )}
        </Box>
    );
}