import React, { useState } from 'react';

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleAccordion} style={styles.accordionButton}>
        {title} {isOpen ? '-' : '+'}
      </button>
      {isOpen && <div style={styles.accordionContent}>{children}</div>}
    </div>
  );
};

const styles = {
    accordionButton: {
      display: 'block',
      width: '100%',
      textAlign: 'left',
      margin: '10px 0',
      padding: '15px',
      backgroundColor: '#f1f1f1',
      border: '2px solid #ccc',
      cursor: 'pointer',
      fontSize: '15px',
    },
    accordionContent: {
      padding: '15px',
      border: '2px solid #ccc',
      borderTop: 'none',
      backgroundColor: '#fff',
    },
  };

export default Accordion;
